var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "md": "10",
      "lg": "8",
      "sm": "12"
    }
  }, [_c('b-card', [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h4', {}, [_c('strong', [_vm._v(" Detail Transaksi ")])])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "9",
      "sm": "12"
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "No. Invoice"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.detailTransaksi ? _vm.detailTransaksi.no_invoice : ""))])])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Transaksi"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.detailTransaksi ? _vm.getDateTime(_vm.detailTransaksi.waktu_transaksi) : "-"))])])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Pembayaran"
    }
  }, [_vm.detailTransaksi && _vm.detailTransaksi.status_bayar == 'lunas' ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" LUNAS ")]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" BELUM LUNAS ")]), _vm.detailTransaksi && _vm.isFinance && _vm.detailTransaksi.status_bayar == 'belum_lunas' ? _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "outline-warning",
      "size": "sm"
    },
    on: {
      "click": _vm.modalEditPembayaran
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "EditIcon"
    }
  })], 1) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Pengiriman"
    }
  }, [_vm.detailTransaksi && _vm.detailTransaksi.status == 'selesai' ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" SELESAI ")]) : _vm.detailTransaksi && _vm.detailTransaksi.status == 'diproses' ? _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(" DIPROSES ")]) : _vm.detailTransaksi && _vm.detailTransaksi.status == 'dikirim' ? _c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" DIKIRIM ")]) : _vm.detailTransaksi && _vm.detailTransaksi.status == 'diterima' ? _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(" DITERIMA ")]) : _vm.detailTransaksi && _vm.detailTransaksi.status == 'dibatalkan' ? _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" DIBATALKAN ")]) : _c('b-badge', {
    attrs: {
      "variant": "secondary"
    }
  }, [_vm._v(" PENDING ")]), _vm.isCabang && _vm.detailTransaksi.status == 'diproses' || _vm.detailTransaksi.status == 'dikirim' ? _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "outline-warning",
      "size": "sm"
    },
    on: {
      "click": _vm.modalEditPengiriman
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "EditIcon"
    }
  })], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-bottom": "5px",
      "font-size": "11px"
    }
  }, [_vm._v("Metode Pembayaran")]), _c('span', [_vm._v(_vm._s(_vm.detailTransaksi && _vm.detailTransaksi.metode_bayar == "manual_transfer" ? "Transfer Manual" : "-"))]), _c('div', {
    staticStyle: {
      "margin-bottom": "5px",
      "margin-top": "10px",
      "font-size": "13px"
    }
  }, [_vm._v("Kurir")]), _c('img', {
    staticStyle: {
      "width": "90px"
    },
    attrs: {
      "src": "https://www.pinclipart.com/picdir/big/162-1620320_file-new-logo-jne-png-wikimedia-commons-tiki.png",
      "alt": ""
    }
  })])], 1), _vm.detailTransaksi.status == 'diproses' || _vm.detailTransaksi.status == 'pending' ? _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "gradient-danger"
    },
    on: {
      "click": _vm.batalkanPesanan
    }
  }, [_vm._v("Batalkan Pesanan")]) : _vm._e()], 1), _c('b-card', [_c('h4', {}, [_c('strong', [_vm._v(" Status Pemesanan ")])]), _c('div', {
    staticClass: "order-status"
  }, _vm._l(_vm.orderStatus, function (status, index) {
    return _c('div', {
      key: index,
      staticClass: "order-status-item",
      class: {
        active: status.active,
        done: status.done
      }
    }, [_c('div', {
      staticClass: "iconPemesanan"
    }, [_c('feather-icon', {
      attrs: {
        "icon": status.icon
      }
    })], 1), _c('div', {
      staticClass: "text"
    }, [_vm._v(_vm._s(status.text))])]);
  }), 0)]), _c('b-modal', {
    attrs: {
      "centered": "",
      "id": "editPembayaran",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "hide-footer": "",
      "title": "Konfirmasi Pembayaran"
    },
    model: {
      value: _vm.showKonfirmasi,
      callback: function callback($$v) {
        _vm.showKonfirmasi = $$v;
      },
      expression: "showKonfirmasi"
    }
  }, [_c('validation-observer', {
    ref: "vkonfirmasi"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Transaksi"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 1,
        text: 'Debit'
      }, {
        value: 2,
        text: 'Kredit'
      }],
      "id": "v-transaction_type",
      "name": "transaction_type"
    },
    model: {
      value: _vm.transactionType,
      callback: function callback($$v) {
        _vm.transactionType = $$v;
      },
      expression: "transactionType"
    }
  })], 1)], 1), _vm.transactionType ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Akun"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "id_akun"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.id_akun,
            "id": "v-id_akun",
            "name": "id_akun"
          },
          model: {
            value: _vm.formkonfirmasi.id_akun,
            callback: function callback($$v) {
              _vm.$set(_vm.formkonfirmasi, "id_akun", $$v);
            },
            expression: "formkonfirmasi.id_akun"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4095445891)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Kas"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "id_kas"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.id_kas,
            "id": "v-id_kas",
            "name": "id_kas"
          },
          model: {
            value: _vm.formkonfirmasi.id_kas,
            callback: function callback($$v) {
              _vm.$set(_vm.formkonfirmasi, "id_kas", $$v);
            },
            expression: "formkonfirmasi.id_kas"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submitkonfirmasi($event);
      }
    }
  }, [_vm._v(" Konfirmasi ")])], 1), _c('b-modal', {
    attrs: {
      "centered": "",
      "id": "editPengiriman",
      "title": "Ubah Status Pengiriman",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.simpanPengiriman($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.optionPengiriman,
      "label": "text"
    },
    model: {
      value: _vm.pengiriman_status,
      callback: function callback($$v) {
        _vm.pengiriman_status = $$v;
      },
      expression: "pengiriman_status"
    }
  })], 1), _vm.no_resi_show ? _c('b-form-group', {
    attrs: {
      "label": "No. Resi"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.no_resi,
      callback: function callback($$v) {
        _vm.no_resi = $$v;
      },
      expression: "no_resi"
    }
  })], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "cancel-form",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.doCancel($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tulis alasan anda membatalkan transaksi"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.cancelForm.keterangan,
      callback: function callback($$v) {
        _vm.$set(_vm.cancelForm, "keterangan", $$v);
      },
      expression: "cancelForm.keterangan"
    }
  })], 1)], 1), _vm.detailTransaksi.bukti_tf ? _c('b-card', [_c('b-card-header', [_vm._v(" Bukti Bayar")]), _c('b-card-body', [_c('center', [_c('img', {
    attrs: {
      "src": _vm.apiFile + _vm.detailTransaksi.bukti_tf.file,
      "alt": "",
      "width": "300px"
    }
  })])], 1)], 1) : _vm._e()], 1)], 1), _vm.detailTransaksi ? _c('div', {
    staticClass: "card",
    attrs: {
      "id": "printableArea"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h3', [_c('b', [_vm._v("INVOICE")])]), _c('span', {
    staticClass: "text--primary h6"
  }, [_c('b', [_vm._v(_vm._s(_vm.detailTransaksi.no_invoice))])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-sm"
  }, [_c('h5', [_c('b', [_vm._v("DITERBITKAN ATAS NAMA")])]), _vm._v(" Penjual : "), _c('b', [_vm._v(_vm._s(_vm.user.karyawan.nama_lengkap))])]), _c('div', {
    staticClass: "col-sm"
  }, [_c('h5', [_c('b', [_vm._v("UNTUK")])]), _c('table', [_c('tr', [_c('td', {
    attrs: {
      "width": "200px"
    }
  }, [_vm._v("Pembeli")]), _c('td', [_c('b', [_vm._v(" " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer ? _vm.detailTransaksi.customer.nama : "-"))])])]), _c('tr', [_c('td', [_vm._v("Tanggal Pembelian")]), _c('td', [_c('b', [_vm._v(_vm._s(_vm.getDate(_vm.detailTransaksi.waktu_transaksi)))])])]), _c('tr', [_c('td', [_vm._v("No. Telepon")]), _c('td', [_c('b', [_vm._v(_vm._s(_vm.detailTransaksi.customer.no_hp ? _vm.detailTransaksi.customer.no_hp : "-"))])])]), _c('tr', [_c('td', {
    staticStyle: {
      "display": "flex"
    }
  }, [_vm._v("Alamat Pembelian")]), _c('td', [_c('b', [_vm._v(_vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer && _vm.detailTransaksi.customer.data_pengiriman ? _vm.detailTransaksi.customer.data_pengiriman.alamat : "-"))]), _vm._v(", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer && _vm.detailTransaksi.customer.data_pengiriman ? _vm.detailTransaksi.customer.data_pengiriman.desa.name : "-") + ", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer && _vm.detailTransaksi.customer.data_pengiriman ? _vm.detailTransaksi.customer.data_pengiriman.kecamatan.name : "-") + ", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer && _vm.detailTransaksi.customer.data_pengiriman ? _vm.detailTransaksi.customer.data_pengiriman.kab_kota.name : "-") + ", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer && _vm.detailTransaksi.customer.data_pengiriman ? _vm.detailTransaksi.customer.data_pengiriman.provinsi.name : "-") + " ")])])])])]), _c('div', {
    staticClass: "table-responsive mt-5"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("INFO PRODUK")]), _c('th', [_vm._v("JUMLAH")]), _c('th', [_vm._v("HARGA BARANG")]), _c('th', [_vm._v("TOTAL HARGA")])])]), _c('tbody', _vm._l(_vm.detailTransaksi.item, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_c('div', {
      staticClass: "text--primary"
    }, [_vm._v(_vm._s(item.barang_nama))])]), _c('td', [_vm._v(_vm._s(item.qty))]), _c('td', [_vm._v("Rp." + _vm._s(_vm.formatRupiah(item.harga)))]), _c('td', [_vm._v("Rp." + _vm._s(_vm.formatRupiah(item.total_harga)))])]);
  }), 0)])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }), _c('div', {
    staticClass: "col-sm"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('b', [_vm._v("TOTAL HARGA")])]), _c('div', [_c('b', [_vm._v("Rp. " + _vm._s(_vm.formatRupiah(_vm.detailTransaksi.uang_masuk)))])])]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_vm._v("Total Ongkos Kirim (1kg)")]), _c('div', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.detailTransaksi.ongkir)))])]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('b', [_vm._v("TOTAL BELANJA")])]), _c('div', [_c('b', [_vm._v("Rp. " + _vm._s(_vm.formatRupiah(_vm.detailTransaksi.total_bayar)))])])]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('b', [_vm._v("TOTAL TAGIHAN")])]), _c('div', [_c('b', [_vm._v("Rp. " + _vm._s(_vm.formatRupiah(_vm.detailTransaksi.total_bayar)))])])])])]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm"
  }, [_vm._v(" Kurir : "), _c('h6', [_c('b', [_vm._v("JNE - Reguler")])])]), _c('div', {
    staticClass: "col-sm"
  }, [_vm._v(" Metode Pembayaran : "), _c('h6', [_c('b', [_vm._v(_vm._s(_vm.detailTransaksi.metode_bayar == "manual_transfer" ? "Transfer Manual" : "-"))])])])])])]) : _vm._e(), _c('b-button', {
    staticClass: "text-center w-100",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.printContent
    }
  }, [_vm._v("Print")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }